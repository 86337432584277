import React, { useState } from 'react';
import { StatefulPopover } from 'baseui/popover';
import { Button } from '..';
import { cannonStyled, useCannonStyletron } from '../../theme';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';
import { ResponsiveContainer } from '../Container';
import useFindYourSafe from '../FindYourSafe/useFindYourSafe';
import language from '../../language';
import NavigationLinks from './NavigationLinks';
import NavigationMenu from './NavigationMenu';
import MenuIcon from '../../assets/menu.svg';
import SearchIcon from '../../assets/search.svg';
import Logo from '../../assets/logo.svg';

// TODO: Add logic for searching products
// TODO: Add user logic

const Container = cannonStyled(
  'div',
  (props: any & { $theme: CannonSafeThemeT }) => ({
    backgroundColor: props.$theme.cannonColors.primaryBlue,
    height: '75px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  })
);

const NavResponsiveContainer = cannonStyled(ResponsiveContainer, {
  justifyContent: 'space-between',
});

const Flex = cannonStyled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export default ({ isTabletOrMobile = false, hidden = false }) => {
  const [css, theme] = useCannonStyletron();
  const [isSticky, setIsSticky] = useState(false);
  const [insideNavbar, setInsideNavbar] = useState(false);

  const { toggleOverlay } = useFindYourSafe();

  const space = css({ marginLeft: theme.sizing.scale300 });
  const sticky = css({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  });

  return (
    <Container
      className={isSticky && sticky}
      onMouseEnter={() => setInsideNavbar(true)}
      onMouseLeave={() => setInsideNavbar(false)}
    >
      <NavResponsiveContainer className='containerResponsive'>
        <Flex
          onClick={() => {
            window.location.href = '/';
          }}
          style={{ width: 'min(30vw, 148px)', cursor: 'pointer' }}
        >
          <Logo />
        </Flex>
        <Flex role='region' aria-label="Main menu">
          {!isTabletOrMobile && (
            <NavigationLinks inside={insideNavbar} hidden={hidden} />
          )}
        </Flex>
        <Flex role='region' aria-label="Main menu buttons">
          {/* <Button.RoundedButton
            onClick={() => {
              window.location.href = '/where-to-buy';
            }}
            size='mini'
            color='secondary'
            $style={{
              paddingTop: '8px',
              paddingBottom: '8px',
              paddingLeft: '12px',
              paddingRight: '12px',
            }}
          >
            Shop Local
          </Button.RoundedButton> */}
          {!isTabletOrMobile && (
            <>
              <span className={space} />
              <Button.RoundedButton
                size='mini'
                color='boneClear'
                onClick={() => {
                  window.location.href = '/safes';
                }}
                $style={{
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                }}
              >
                <label style={{ fontWeight: 600, cursor: 'inherit' }}>
                  View all safes
                </label>
              </Button.RoundedButton>
            </>
          )}
          {/* HIDDEN for future realease */}
          {/* <Button.IconButton className={css({ marginLeft: '30px' })}>
            <SearchIcon />
          </Button.IconButton> */}
          {isTabletOrMobile && (
            <StatefulPopover
              overrides={{
                Body: {
                  style: {
                    padding: '0px 8px 0px 8px',
                    zIndex: 100000,
                    background: theme.cannonColors.primaryBlue,
                  },
                },
              }}
              content={({ close }) => <NavigationMenu close={close} aria-label='close navbar' />}
              accessibilityType='menu'
            >
              <Button.IconButton>
                <MenuIcon alt='main menu icon' aria-label='Main menu icon'/>
              </Button.IconButton>
            </StatefulPopover>
          )}
        </Flex>
      </NavResponsiveContainer>
    </Container>
  );
};
